import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { Alert, Box, Button, ButtonGroup, Grid, Paper, Typography, Chip, FormControl, Select, MenuItem } from '@mui/material';

import DataLoadsStatus from 'src/components/DataLoadsStatus';
import SideNavigation from 'src/components/SideNavigation';
import { useSearchParams } from 'react-router-dom';
import PageTitle from 'src/components/PageTitle';
import { getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations, getAllDataloadsHistory } from 'src/api';
import ErrorFileDownload from 'src/components/DataLoadsStatus/ErrorFileDownload';

const drawerWidth = 400;

function CustomerAccountFileActivity() {
  const [searchParams] = useSearchParams();
  const carrierId = searchParams.get('id');
  const carrierStatus = searchParams.get('status');
  const companyName = searchParams.get('name');
  const [tab, setTab] = useState(searchParams.get('tab'));
  const [matchesFilter, setMatchesFilter] = useState('all');

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [customerFileConfig, setCustomerFileConfig] = useState(null);
  const [customerFileDataLoads, setCustomerFileDataLoads] = useState([]);
  const [customerFileDataTableLoading, setCustomerFileDataTableLoading] = useState(true);

  const [deathFileConfig, setDeathFileConfig] = useState(null);
  const [deathFileDataLoads, setDeathFileDataLoads] = useState([]);
  const [deathFileDataTableLoading, setDeathFileDataTableLoading] = useState(true);

  const [matchesFileConfig, setMatchesFileConfig] = useState(null);
  const [matchesDataLoads, setMatchesDataLoads] = useState([]);
  const [matchesDataTableLoading, setMatchesDataTableLoading] = useState(true);
  const [isDownloadError, setIsDownloadError] = useState(false);

  const fileTypes = ['Customer Lists', 'Verified Death Lists', 'Match Results']

  const [isDataTableLoading, setIsDataTableLoading] = useState(customerFileDataTableLoading);

  const [dataType, setDataType] = useState('customerFile');
  const [dataLoads, setDataLoads] = useState([]);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [cutoffTime, tz] = DateTime.fromISO('2023-09-25T12:00:00Z', { zone: 'utc' })
    .setZone(timeZone)
    .toFormat('ha ZZZZ').split(' ');

  const deliveryTime = DateTime.fromISO('2023-09-25T15:00:00Z', { zone: 'utc' })
    .setZone(timeZone)
    .toFormat('ha');

  function handleDownloadError() {
    setIsDownloadError(true);
  }

  function clearDownloadError() {
    setIsDownloadError(false);
  }

  function dataLoadTableMap(item) {
    item.variant = item?.fileType === 'customer-files' ? 'customer lists table' : 'match results table';
    item.status = item?.fileStatus;
    item.completed = item?.fileStatus === 'Skipped' ? '-' : item?.completed;
    item.totalMatches = item?.fileStatus === 'Skipped' ? '-' : item?.totalMatches;
    item.otherMatches = item?.updatedMatches;

    const colors = {
      'Processing': 'info',
      'Failed': 'error',
      'Errors Found': 'warning',
      'Successful': 'success',
      'Skipped': 'default'
    };
    const color = colors[item?.fileStatus] || 'default';
    const displayDownload = parseInt(item?.numOfErrors) > 0 || item?.fileStatus === 'Failed';
    item.fileStatus = item?.fileStatus && <Chip size="small" color={color} className={item?.fileStatus} label={item?.fileStatus} variant="outlined" />
    if (displayDownload) {
      item.downloadFile = <ErrorFileDownload paddingTop="10px" height="40px" handleDownloadError={handleDownloadError} fileType={item?.fileType === 'customer-files' ? item?.fileType : 'match-files'} dataLoadId={item?.id ? item?.id : 55555} />
    }
    return item
  }

  useEffect(() => {
    const getCustomerFileConfig = async function () {
      const customerFileConfigurations = await getCustomerFileConfigurations(carrierId);
      setCustomerFileConfig(customerFileConfigurations);
    };

    const getDeathFileConfig = async function () {
      const deathFileConfigurations = await getDeathFileConfigurations(carrierId);
      setDeathFileConfig(deathFileConfigurations);
    };

    const getMatchFileConfig = async function () {
      const matchesFileConfigurations = await getMatchFileConfigurations(carrierId);
      setMatchesFileConfig(matchesFileConfigurations);
    };


    if (carrierId && (carrierStatus === 'Live' || carrierStatus === 'On-boarding')) {
      getCustomerFileConfig();
      getDeathFileConfig();
      getMatchFileConfig();
    };

    if (carrierStatus === 'Prospect') {
      setCustomerFileDataTableLoading(false);
      setDeathFileDataTableLoading(false);
      setMatchesDataTableLoading(false);
    }
  }, [carrierId, carrierStatus]);

  useEffect(() => {
    const customerFileDataLoads = async function () {
      const _customerFileDataLoads = await getAllDataloadsHistory(carrierId, 'customer-files');
      let _dataLoadsWithPrefix = _customerFileDataLoads.map(v => ({ ...v, fileType: 'customer-files' }));
      _dataLoadsWithPrefix = _dataLoadsWithPrefix.map(dataLoadTableMap);
      setCustomerFileDataLoads(_dataLoadsWithPrefix);
      setCustomerFileDataTableLoading(false);
    };

    if (carrierId && customerFileConfig?.length > 0) {
      customerFileDataLoads();
    }
    else if (customerFileConfig?.length === 0) {
      setCustomerFileDataTableLoading(false);
    }

  }, [carrierId, customerFileConfig]);

  useEffect(() => {
    if(!tab || tab === 'customer') {
      setDataLoads(customerFileDataLoads);
      setDataType('customerFile');
      setIsDataTableLoading(customerFileDataTableLoading);
      setSelectedIndex(0);
    }
  }, [customerFileDataLoads, customerFileDataTableLoading]);

  useEffect(() => {
    const deathFileDataLoads = async function () {
      const _deathFileDataLoads = await getAllDataloadsHistory(carrierId, 'death-files');
      const _dataLoadsWithPrefix = _deathFileDataLoads.map(v => ({ ...v, fileType: 'death-files' }))
      setDeathFileDataLoads(_dataLoadsWithPrefix);
      setDeathFileDataTableLoading(false);
    };

    if (carrierId && deathFileConfig?.length > 0) {
      deathFileDataLoads();
    }
    else if (deathFileConfig?.length === 0) {
      setDeathFileDataTableLoading(false);
    }

  }, [carrierId, deathFileConfig]);

  useEffect(() => {
    const matchesDataLoads = async function () {
      let _matchesDataLoads = await getAllDataloadsHistory(carrierId, 'match-files');
      _matchesDataLoads = _matchesDataLoads.map(dataLoadTableMap);
      setMatchesDataLoads(_matchesDataLoads);
      setMatchesDataTableLoading(false);
    };

    if (carrierId && matchesFileConfig?.length > 0) {
      matchesDataLoads();
    }
    else if (matchesFileConfig?.length === 0) {
      setMatchesDataTableLoading(false);
    }

  }, [carrierId, matchesFileConfig]);

  const handleMenuItemClick = (event) => {
    const index = Number(event.target.id)
    setSelectedIndex(index);

    const publishData = function (publishDataLoad, publishDataType, publishIsDataTableLoading) {
      setDataLoads(publishDataLoad);
      setDataType(publishDataType);
      setIsDataTableLoading(publishIsDataTableLoading);
    }

    if (index === 0) {
      publishData(customerFileDataLoads, 'customerFile', customerFileDataTableLoading);
      setTab('customer');
    } else if (index === 1) {
      publishData(deathFileDataLoads, 'deathFile', deathFileDataTableLoading);
      setTab('death');
    } else if (index === 2) {
      setMatchesFilter('all');
      publishData(matchesDataLoads, 'matches', matchesDataTableLoading);
      setTab('match');
    }
  };

  useEffect(() => {
    if (tab === 'match') {
      handleMenuItemClick({target: {id: "2"}});
    }
  }, [tab, matchesDataLoads])

  const handleFilterMatches = (event) => {
    setMatchesFilter(event.target.value);
    let _filteredData = matchesDataLoads.filter((value) => {
      if (event.target.value === 'all') {
        return value;
      } 
      if (event.target.value === 'private') {
        return (value.incognito === true);
      }
      if (event.target.value === 'customer-facing') {
        return (!value?.incognito);
      }
      return null;
    })
    setDataLoads(_filteredData);
  }

  return (
    <>
      <PageTitle title={companyName} />
      <Box sx={{ display: 'flex' }}>
        <SideNavigation drawerWidth={drawerWidth} selected="fileActivity" />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Typography variant="h6" color="primary.text" sx={{ ml: { xs: 9, md: 0 }, mb: { xs: 4, md: 3 }, fontWeight: 700 }}>
            DATA LOADS & MATCH ACTIVITY
          </Typography>
          <ButtonGroup sx={{ mb: 4 }}>
            {fileTypes.map((type, i) => {
              return <Button key={type} onClick={handleMenuItemClick} id={i} variant={selectedIndex === i ? "contained" : "outlined"}>{type}</Button>
            })}
          </ButtonGroup>
          {selectedIndex === 2 &&
            <Grid container >
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mb: 4 }}>Match Results</Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <FormControl>
                  <Select
                    id="matches-filter-select"
                    value={matchesFilter}
                    onChange={handleFilterMatches}
                    sx={{width: '280px', textAlign: 'left', mt: '-15px' }}
                  >
                    <MenuItem value={'all'}>All Match Results</MenuItem>
                    <MenuItem value={'private'}>Matches Private to Evadata</MenuItem>
                    <MenuItem value={'customer-facing'}>Customer Facing Matches</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          }
          <Grid container spacing={3} >
            <Grid item xs={12}>
              <Paper sx={{ mb: 2, mt: 1 }}>
                {(dataLoads?.length > 0 && selectedIndex !== 2) &&
                  <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 0 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ m: 1 }} variant="subtitle1">Submitted Lists</Typography>
                    </Grid>
                  </Grid>
                }
                <Alert className={isDownloadError ? 'show' : 'hide'} sx={{ mb: 2, mr: 3 }} onClick={clearDownloadError} severity="error">An error was encountered when retrieving the file, try downloading it again.</Alert>
                <DataLoadsStatus
                  dataType={dataType}
                  dataLoads={dataLoads}
                  isNewUser={'false'}
                  isDataTableLoading={isDataTableLoading}
                  filterApplied={matchesFilter !== 'all'}
                />
              </Paper>
              {selectedIndex === 2 && <Typography variant="body2">Customer list submissions before {`${cutoffTime.toLowerCase()} ${tz}`} are processed in matching that day M-F. Results returned are based on each match file’s transfer schedule. For daily or one time deliveries, match results are sent to the customer’s preferred transfer location the following day {`${deliveryTime.toLowerCase()} ${tz}`}.</Typography> }
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>

  );
}

export default CustomerAccountFileActivity;
