import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, styled, Box, Typography, Grid, Tooltip, Link  } from '@mui/material';
import { ExpandLess, ExpandMore, VpnKeyRounded, InfoRounded } from '@mui/icons-material';
import { months } from '../../utilities/months';
import _ from 'lodash';

function DisplayMoreDetails({ title, encryptionEnabled, auditDate, auditUser, frequency, reportDates, frequencyDay, method, holidayCalendarId, annualDmf, emails, openCalendarDrawer, setHolidayCalendarId }) {

  const EmailAccordionWrapper = styled(Box)(
    () => `
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    `
  );

  const EncryptionWrapper = styled(Grid)(
    ({theme}) => `
      .MuiCard-root {
        padding: 0px !important;
      }
      .key-icons {
        width: 16px;
        float: left;
        margin-right: 4px;
      }
      .success {
        color: ${theme.palette.success.main};
      }
      .error {
        color: ${theme.palette.error.main};
      }
    `
  );

  const [showEmails, setShowEmails] = useState(false);

  function toggleShowEmails() {
    setShowEmails(!showEmails);
  }

  function openDrawer() {
    openCalendarDrawer();
    setHolidayCalendarId(holidayCalendarId);
  }

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="caption">Added {auditDate} by {auditUser}</Typography>
      <EncryptionWrapper sx={{ py: '3px' }} >
        <Typography variant="caption" className={(encryptionEnabled || title === 'Match Results Details' )? 'success' : 'error'} >
          <VpnKeyRounded className="key-icons" />{method === 'sftp' ? 'PGP ' : 'KMS '}{(encryptionEnabled || title === 'Match Results Details') ? 'Key Encryption ON' : 'Key Encryption OFF'}
        </Typography>
      </EncryptionWrapper>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1, mt: 3 }}>
        <Typography variant="subtitle1">Transfer Schedule: <Typography variant="body1" display="inline"  sx={{textTransform: 'capitalize'}}>{frequency} {frequencyDay ? '(' + frequencyDay + ')' : ''}</Typography></Typography>
        {title === 'Match Results Details' && <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.'><InfoRounded color="primary" /></Tooltip>}
      </Box>
      {title === 'Match Results Details' && frequency === 'Quarterly' &&
        <>
          <Typography variant='subtitle2' color='text.primary' sx={{mt: 0.5, mb: 0.5}}>Quarterly delivery dates, scheduled to deliver repeatedly year after year:</Typography>
          {reportDates.map((date, i) => {
            return(
              <>
                <Typography variant='subtitle1' sx={{display: 'inline', mb: 0.5}}>Q{i+1}: </Typography>
                <Typography sx={{display: 'inline'}}>{months[Number(_.split(date, '/')[0]-1)]} {Number(_.split(date, '/')[1])}</Typography>
                <br/>
              </>
            )
          })}
        </>
      }
      {title === 'Match Results Details' && frequency === 'Daily (Monday-Friday)' &&
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography variant="subtitle1" color="text.primary" sx={{  mr: 1}}>Observe Company Holidays:</Typography>
            <Typography variant="body1" color="text.primary">{holidayCalendarId ? 'Yes' : 'No'}</Typography>
            {holidayCalendarId && <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Match jobs scheduled to deliver to this organization Daily will skip holidays specified in their company’s holiday calendar, and will resume the following day (Mon.-Fri.).'><InfoRounded color="primary" fontSize='small'/></Tooltip>}
          </Box>
          <Link href='#' onClick={openDrawer} variant='body2'>See List of Holidays</Link>
        </>
      }
      {title === 'Match Results Details' && annualDmf &&
        <Typography variant="subtitle1">Annual DMF Delivery Date: <Typography display="inline">{months[Number(annualDmf.split('/')[0]) - 1]} {Number(annualDmf.split('/')[1])}</Typography></Typography>
      }
      <Typography variant="subtitle1" sx={{ mt: 1 }}>Transfer Location Method: <Typography variant="body1" display="inline">{method === 'sftp' ? 'SFTP' : 'AWS'}</Typography> </Typography>
      <EmailAccordionWrapper>
        <Button className="pgp-button show-hide" variant="text" size="medium" onClick={toggleShowEmails}>Notification Emails {showEmails ? <ExpandLess /> : <ExpandMore />}</Button>
      </EmailAccordionWrapper>
      {showEmails &&
        <>
          {emails.map((email) => {
            return <Typography variant='subtitle1' key={email}>{email}</Typography>
          })}
        </>
      }

    </>
  )
}

DisplayMoreDetails.propTypes = {
  title: PropTypes.string, 
  encryptionEnabled: PropTypes.bool,
  auditDate: PropTypes.string, 
  auditUser: PropTypes.string, 
  frequency: PropTypes.string, 
  reportDates: PropTypes.array,
  frequencyDay: PropTypes.string, 
  method: PropTypes.string,
  emails: PropTypes.array,
  holidayCalendarId: PropTypes.string,
  annualDmf: PropTypes.string,
  openCalendarDrawer: PropTypes.bool,
  setHolidayCalendarId: PropTypes.func
}

export default DisplayMoreDetails;